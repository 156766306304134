import axios, { isAxiosError } from "axios";

const playlistFunctionUrl = process.env.PLAYLIST_FUNCTION_ENDPOINT;

export interface Music {
    name: string,
    artist: string,
    spotifyLink: string,

}

export async function fetchPlaylist() {
    try {
        const playlist = await axios.get(playlistFunctionUrl!)
        const randomSongIndex = Math.floor(Math.random() * 20);
        let song: any;
        if (playlist) {
            song = playlist.data[randomSongIndex].track;
            const music: Music = {
                name: song.name,
                artist: song.artists[0].name,
                spotifyLink: song.external_urls.spotify,
            }
            return music;
        } else {
            return {
                name: "The Night We Met",
                artist: "Lord Huron",
                spotifyLink: "https://open.spotify.com/track/3hRV0jL3vUpRrcy398teAU?si=c9534345e9c74c94"
            }
        }
    } catch (error) {
        console.log(error);
    }

}

export async function getPlaylist() {
    try {
        const playlist = await axios.get(playlistFunctionUrl!)
        return playlist.data;
    } catch (error) {
        console.log(error);
    }
}

export async function getBlogIndexStore(blogsPublicIndexUrl: string, blogsPublicStoreUrl: string) {
    const store = await axios.get(blogsPublicStoreUrl);
    const index = await axios.get(blogsPublicIndexUrl);
    return { store, index };
}

const brevoUrl = process.env.BREVO_CONTACTS_ENDPOINT;

export async function addToBrevo(email: string) {
    try {
        const res = await axios.post(brevoUrl!, { email })
        return res.data;
    } catch (error) {
        if (isAxiosError(error)) {
            return error.response?.data
        }
    }
}

const sanityUrl = process.env.SANITY_ENDPOINT;

export async function updateViewCount(id: string) {
    try {
        // eslint-disable-next-line no-unused-vars
        const res = await axios.post(sanityUrl!, { id });

    } catch (error) {
        console.log(error)
    }
}
