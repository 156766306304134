import React, { createContext, useState, useEffect } from "react";
import { getPlaylist } from "../api/retrieve";

const defaultPlaylist = [
  {
    track: {
      name: "The Night We Met",
      artists: [{ name: "Lord Huron" }],
      external_urls: {
        spotify:
          "https://open.spotify.com/track/3hRV0jL3vUpRrcy398teAU?si=c9534345e9c74c94",
      },
    },
  },
];

export const StateContext = createContext({
  playlist: defaultPlaylist,
  updatePlaylist: () => {},
  dark: false,
  toggleDark: () => {},
});

const supportsDarkMode = () =>
  window.matchMedia("(prefers-color-scheme: dark)").matches === true;

export const StateProvider = ({ children }: any) => {
  const [playlist, setPlaylist] = useState(defaultPlaylist);
  const [dark, setDark] = useState(supportsDarkMode);

  const toggleDark = () => {
    setDark((prev) => !prev);
  };

  const updatePlaylist = () => {
    setPlaylist([]);
  };

  const fetchData = async () => {
    try {
      const data = await getPlaylist();
      setPlaylist(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <StateContext.Provider
      value={{ playlist, updatePlaylist, dark, toggleDark }}
    >
      {children}
    </StateContext.Provider>
  );
};
